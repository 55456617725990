.overviewBackground {
    background-clip: padding-box;
    background-size: auto 100%;
    background-position: left;
    background-repeat: repeat;
    background-image: linear-gradient(
            270deg,
            #0f0f1e 34.24%,
            rgba(15, 15, 30, 0) 63.37%
        ),
        linear-gradient(180deg, #0f0f1e 50.16%, rgba(15, 15, 30, 0) 112.62%),
        url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOcAAAEVCAYAAAAb2fKGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQXSURBVHgB7dqhcUNRDAXR99NYWghKK6GmaSUoLbgyuwULWLPgHHzpzgjonBd9f/3ebG1t97YfB0gSJ0SJE6LECVHihChxQpQ4IUqcECVOiBInRIkToq5X//2ucz4f59xtbW3fvx3xkGxru7t11kKUOCFKnBAlTogSJ0SJE6LECVHihChxQpT3PVvb4HbEW5Wt7e7WWQtR4oQocUKUOCFKnBAlTogSJ0SJE6LECVHe92xtg9sRb1W2trtbZy1EiROixAlR4oQocUKUOCFKnBAlTogSJ0R537O1DW5HvFXZ2u5unbUQJU6IEidEiROixAlR4oQocUKUOCFKnBDlfc/WNrgd8VZla7u7ddZClDghSpwQJU6IEidEiROixAlR4oQocUKU9z1b2+B2xFuVre3u1lkLUeKEKHFClDghSpwQJU6IEidEiROixAlR3vdsbYPbEW9Vtra7W2ctRIkTosQJUeKEKHFClDghSpwQJU6IEidEed+ztQ1uR7xV2drubp21ECVOiBInRIkTosQJUeKEKHFClDghSpwQ5X3P1ja4HfFWZWu7u3XWQpQ4IUqcECVOiBInRIkTosQJUeKEKHFClPc9W9vgdsRbla3t7tZZC1HihChxQpQ4IUqcECVOiBInRIkTosQJUd73bG2D2xFvVba2u1tnLUSJE6LECVHihChxQpQ4IUqcECVOiBInRHnfs7UNbke8Vdna7m6dtRAlTogSJ0SJE6LECVHihChxQpQ4IUqcEOV9z9Y2uB3xVmVru7t11kKUOCFKnBAlTogSJ0SJE6LECVHihChxQpT3PVvb4HbEW5Wt7e7WWQtR4oQocUKUOCFKnBAlTogSJ0SJE6LECVHe92xtg9sRb1W2trtbZy1EiROixAlR4oQocUKUOCFKnBAlTogSJ0R537O1DW5HvFXZ2u5unbUQJU6IEidEiROixAlR4oQocUKUOCFKnBDlfc/WNrgd8VZla7u7ddZClDghSpwQJU6IEidEiROixAlR4oQocUKU9z1b2+B2xFuVre3u1lkLUeKEKHFClDghSpwQJU6IEidEiROixAlR3vdsbYPbEW9Vtra7W2ctRIkTosQJUeKEKHFClDghSpwQJU6IEidEed+ztQ1uR7xV2drubp21ECVOiBInRIkTosQJUeKEKHFClDghSpwQ5X3P1ja4HfFWZWu7u3XWQpQ4IUqcECVOiBInRIkTosQJUeKEKHFClPc9W9vgdsRbla3t7tZZC1HihChxQpQ4IUqcECVOiBInRIkTosQJUd73bG2D2xFvVba2u1tnLUSJE6LECVHihChxQpQ4IUqcECVOiBInRHnfs7UNbke8Vdna7m6dtRAlTogSJ0SJE6LECVHihChxQpQ4IUqcEHVNvhaAHX//P7cnZ0BilM0LbWUAAAAASUVORK5CYII=");
}
