/* Typography Styles */
/* NOTE - these will remain unused until we procure a license for the TWKLausanne font */

/* Lausanne-100 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-100.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-100.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-100.ttf")
            format("truetype");
}

/* Lausanne-100Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-100Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-100Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-100Italic.ttf")
            format("truetype");
}

/* Lausanne-200 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-200.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-200.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-200.ttf")
            format("truetype");
}

/* Lausanne-200Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-200Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-200Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-200Italic.ttf")
            format("truetype");
}

/* Lausanne-300 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-300.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-300.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-300.ttf")
            format("truetype");
}

/* Lausanne-300Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-300Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-300Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-300Italic.ttf")
            format("truetype");
}

/* Lausanne-400 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-400.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-400.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-400.ttf")
            format("truetype");
}

/* Lausanne-400Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-400Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-400Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-400Italic.ttf")
            format("truetype");
}

/* Lausanne-500 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-500.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-500.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-500.ttf")
            format("truetype");
}

/* Lausanne-500Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-500Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-500Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-500Italic.ttf")
            format("truetype");
}

/* Lausanne-600 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-600.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-600.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-600.ttf")
            format("truetype");
}

/* Lausanne-600Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-600Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-600Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-600Italic.ttf")
            format("truetype");
}

/* Lausanne-700 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-700.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-700.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-700.ttf")
            format("truetype");
}

/* Lausanne-700Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-700Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-700Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-700Italic.ttf")
            format("truetype");
}

/* Lausanne-800 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-800.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-800.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-800.ttf")
            format("truetype");
}

/* Lausanne-800Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-800Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-800Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-800Italic.ttf")
            format("truetype");
}

/* Lausanne-900 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-900.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-900.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-900.ttf")
            format("truetype");
}

/* Lausanne-900Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-900Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-900Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-900Italic.ttf")
            format("truetype");
}

/* Lausanne-1000 */
@font-face {
    font-family: "Lausanne";
    font-style: normal;
    font-weight: 1000;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-1000.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-1000.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-1000.ttf")
            format("truetype");
}

/* Lausanne-1000Italic */
@font-face {
    font-family: "Lausanne";
    font-style: italic;
    font-weight: 1000;
    font-display: swap;
    src: url("https://cdn.castle.finance/fonts/woff/TWKLausanne-1000Italic.woff2")
            format("woff2"),
        url("https://cdn.castle.finance/fonts/woff/TWKLausanne-1000Italic.woff")
            format("woff"),
        url("https://cdn.castle.finance/fonts/ttf/TWKLausanne-1000Italic.ttf")
            format("truetype");
}
