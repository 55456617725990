@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/typography.css";

body {
    margin: 0;
    @apply font-sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* REAVIZ STYLES */
    --color-background: rgb(51, 51, 51);
    --color-on-background: #fff;

    --color-tooltip: rgba(0, 5, 11, 0.9);
    --color-on-tooltip: #fff;

    --color-handle-fill: #2c343a;
    --color-handle-stroke: #67c2e4;
    --color-handle-drag-fill: transparent;
    --color-handle-dots: #67c2e4;
    --color-handle-line: #67c2e4;
}

.navbar-wallet-adapter-button {
    height: 40px !important;
}

.live-green {
    transform: scale(1);
    border-radius: 50%;
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    90% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.bg-primary {
    background-color: #111120 !important;
}

.bg-muted {
    background-color: #151526 !important;
}

.border-muted {
    border-color: #222234 !important;
}

.wallet-adapter-modal-wrapper {
    background-color: #111120 !important;
}

.wallet-adapter-button {
    /* border: 1px solid #222234 !important; */
    /* padding: 0.5rem; */
    /* background-color: #111120 !important; */
}

.wallet-adapter-modal-wrapper {
    padding: 3rem !important;
    max-width: 500px;
}

.swap-card-background {
    background-color: #151526 !important;
}

/* Patterned Background */
.patterned-background {
    background-clip: padding-box;
    background-position: left;
    background-size: auto 8%;
    background-repeat: repeat;
    background-image: linear-gradient(
            270deg,
            #0f0f1e 34.24%,
            rgba(15, 15, 30, 0) 63.37%
        ),
        linear-gradient(185deg, #0f0f1e 50.16%, rgba(15, 15, 30, 0) 112.62%),
        url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA7CAYAAAAn+enKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAY5JREFUaIHt18Erg3Ecx/HP7zdsLHLeVS1TEv6BHVAI47ApxM3BAcWc6LnaDso/wEm2FY+EwtFNyYFIubhyoLTYbF9X7YBa/X719Hkdn2c9733q2bMNICIiIiIiIrIjPpIeGh9eD9loaxtRpctL0Dpso21lsE0c7HUc7HUc7HUcbISoNxEM2EhbGewrlqahJBofTaUBUSbbRmM/xWIbzX4pnijgurUzP+c4TtlEt6rBMzNOoPDsr//rdcWGz2Iu57xXHp/o32z6qvs4hsZdpCM/a2J0VYMTsfQCIGv/yNRCYTKzv3xQeWaqLx0sBOUQgm4Apd+uIpCPrLtS1b8sI7d0YizVhRKORKn5rLucrTwfjTo1oaC/8a/r5GsD4rqLr9W8F2Of4cToekREnWpgdddNbpvqVvKZCt3en7+0hXuOlVJb7ZHews392aWp9k/Gn9KJWKoFggv4MJjZS16Z7hv/Hs64yUdoeVBl1WS6DfCnpfdxsNdxsNdxsNfZGVzWO9r39WSlTURERERERAR8A24+aE4O5G3MAAAAAElFTkSuQmCC");
}
