.vaultCard {
    border: 1px solid #222234;
    background-color: #151526;
    background-clip: padding-box;
    background-size: auto 120%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: radial-gradient(
            30% 20% at 50% 52.62%,
            rgba(21, 21, 38, 0.4) 0%,
            #111120 100%
        ),
        url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPIAAAEfCAYAAAB7xZtMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQySURBVHgB7dqxTUUBDATBB43RAhH1kFIWlUENzu6vZuJLV3Lg5zn4+vz5trW13du+P8DLEzIECBkChAwBQoYAIUOAkCFAyBAgZAgQMgQIGQLeLv+cb8/z8fc8v7a2tlvbEw/qtrabW6c1BAgZAoQMAUKGACFDgJAhQMgQIGQIEDIEeNG0tQ1sT7zD2dpubp3WECBkCBAyBAgZAoQMAUKGACFDgJAhQMgQ4EXT1jawPfEOZ2u7uXVaQ4CQIUDIECBkCBAyBAgZAoQMAUKGACFDgBdNW9vA9sQ7nK3t5tZpDQFChgAhQ4CQIUDIECBkCBAyBAgZAoQMAV40bW0D2xPvcLa2m1unNQQIGQKEDAFChgAhQ4CQIUDIECBkCBAyBHjRtLUNbE+8w9nabm6d1hAgZAgQMgQIGQKEDAFChgAhQ4CQIUDIEOBF09Y2sD3xDmdru7l1WkOAkCFAyBAgZAgQMgQIGQKEDAFChgAhQ4AXTVvbwPbEO5yt7ebWaQ0BQoYAIUOAkCFAyBAgZAgQMgQIGQKEDAFeNG1tA9sT73C2tptbpzUECBkChAwBQoYAIUOAkCFAyBAgZAgQMgR40bS1DWxPvMPZ2m5undYQIGQIEDIECBkChAwBQoYAIUOAkCFAyBDgRdPWNrA98Q5na7u5dVpDgJAhQMgQIGQIEDIECBkChAwBQoYAIUOAF01b28D2xDucre3m1mn9wOsTMgQIGQKEDAFChgAhQ4CQIUDIECBkCPCiaWsb2J54h7O13dw6rSFAyBAgZAgQMgQIGQKEDAFChgAhQ4CQIcCLpq1tYHviHc7WdnPrtIYAIUOAkCFAyBAgZAgQMgQIGQKEDAFChgAvmra2ge2Jdzhb282t0xoChAwBQoYAIUOAkCFAyBAgZAgQMgQIGQK8aNraBrYn3uFsbTe3TmsIEDIECBkChAwBQoYAIUOAkCFAyBAgZAjwomlrG9ieeIeztd3cOq0hQMgQIGQIEDIECBkChAwBQoYAIUOAkCHAi6atbWB74h3O1nZz67SGACFDgJAhQMgQIGQIEDIECBkChAwBQoYAL5q2toHtiXc4W9vNrdMaAoQMAUKGACFDgJAhQMgQIGQIEDIECBkCvGja2ga2J97hbG03t05rCBAyBAgZAoQMAUKGACFDgJAhQMgQIGQI8KJpaxvYnniHs7Xd3DqtIUDIECBkCBAyBAgZAoQMAUKGACFDgJAhwIumrW1ge+IdztZ2c+u0hgAhQ4CQIUDIECBkCBAyBAgZAoQMAUKGAC+atraB7Yl3OFvbza3TGgKEDAFChgAhQ4CQIUDIECBkCBAyBAgZArxo2toGtife4WxtN7dOawgQMgQIGQKEDAFChgAhQ4CQIUDIECBkCPCiaWsb2J54h7O13dw6rSFAyBAgZAgQMgQIGQKEDAFChgAhQ4CQIeAfLIvdsCwLNrMAAAAASUVORK5CYII=");
}

.vaultCardHoverable {
    cursor: "pointer";
}

.vaultCard.active {
    border: 1px solid rgba(246, 221, 1, 0.2);
}

.vaultCardHoverable:hover {
    border: 1px solid rgba(246, 221, 1, 0.2);
    box-shadow: 0px 8px 48px rgba(246, 221, 1, 0.08);
}

.vaultCard.active,
.vaultCardHoverable:hover .header {
    background: linear-gradient(
        180deg,
        rgba(246, 221, 1, 0.12) 0%,
        rgba(17, 17, 32, 0) 97.88%
    );
}

.yieldRow {
    background: rgba(255, 255, 255, 0.04);
}
